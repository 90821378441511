@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply sm:text-5xl text-3xl font-semibold text-default-500;
    }

    h2 {
        @apply md:text-4xl text-2xl font-semibold text-default-500;
    }

    h3 {
        @apply md:text-3xl text-xl text-default-500;
    }

    h4 {
        @apply md:text-2xl text-lg text-default-500;
    }
    
    .pageLoading {
        
    }

    .loadingIndicator {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .loadingIndicator div {
        animation: loadingIndicator 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
    }

    .loadingIndicator div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: var(--color-primary);
        margin: -4px 0 0 -4px;
    }


    .loadingIndicator div:nth-child(1) {
        animation-delay: -0.036s;
    }

    .loadingIndicator div:nth-child(1):after {
        top: 63px;
        left: 63px;
    }

    .loadingIndicator div:nth-child(2) {
        animation-delay: -0.072s;
    }

    .loadingIndicator div:nth-child(2):after {
        top: 68px;
        left: 56px;
    }

    .loadingIndicator div:nth-child(3) {
        animation-delay: -0.108s;
    }

    .loadingIndicator div:nth-child(3):after {
        top: 71px;
        left: 48px;
    }

    .loadingIndicator div:nth-child(4) {
        animation-delay: -0.144s;
    }

    .loadingIndicator div:nth-child(4):after {
        top: 72px;
        left: 40px;
    }

    .loadingIndicator div:nth-child(5) {
        animation-delay: -0.18s;
    }

    .loadingIndicator div:nth-child(5):after {
        top: 71px;
        left: 32px;
    }

    .loadingIndicator div:nth-child(6) {
        animation-delay: -0.216s;
    }

    .loadingIndicator div:nth-child(6):after {
        top: 68px;
        left: 24px;
    }

    .loadingIndicator div:nth-child(7) {
        animation-delay: -0.252s;
    }

    .loadingIndicator div:nth-child(7):after {
        top: 63px;
        left: 17px;
    }

    .loadingIndicator div:nth-child(8) {
        animation-delay: -0.288s;
    }

    .loadingIndicator div:nth-child(8):after {
        top: 56px;
        left: 12px;
    }

    @keyframes loadingIndicator {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
    @keyframes wiggle {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(25deg);
        }
        50% {
            transform: rotate(0deg);
        }
        75%{
            transform: rotate(-25deg);
        }
        100%{
            transform: rotate(0deg);
        }
    }
}

@layer components {
    .label{
        @apply text-default-500;
    }
    .input-box {
        @apply placeholder-gray-400 p-2 m-auto block rounded-md border border-default-200 focus:border-primary focus:ring-default-500 focus:ring-0 sm:text-sm w-full focus:border focus-visible:outline-none;
    }
    .select{
        @apply placeholder-gray-400 py-2 block rounded-md border border-default-200 sm:text-sm focus:border focus-visible:outline-none focus:ring-0 focus:border-primary focus:ring-primary;
    }
    .btn-primary{
        @apply transition duration-200 rounded-md bg-primary py-2 px-4 hover:bg-red-700 hover:cursor-pointer text-white focus:outline-none focus:ring-offset-2 hover:text-white ;
    }
    .btn-secondary {
        @apply transition duration-100 rounded-md bg-white py-2 px-4 hover:bg-gray-100 text-primary focus:outline-none focus:ring-offset-2 hover:text-primary ;
    }
    .btn-disabled{
        @apply transition duration-100 rounded-md bg-gray-300 py-2 px-4 hover:cursor-not-allowed text-white focus:outline-none focus:ring-offset-0;
    }
    .btn-disabled-outline {
        @apply transition duration-100 rounded-md bg-white py-2 px-4 border-gray-300 border hover:cursor-not-allowed text-gray-300 focus:outline-none focus:ring-offset-0;
    }
    .btn-outline-primary{
        @apply transition duration-200 rounded-md bg-transparent border border-primary py-2 px-4 hover:bg-primary hover:text-white focus:outline-none text-primary;
    }
    .btn-default{
        @apply transition duration-100 rounded-md bg-white py-2 px-4 text-default-500 focus:outline-none focus:ring-offset-2 hover:shadow-red-300 align-middle disabled:bg-gray-200 disabled:cursor-not-allowed disabled:shadow-none disabled:translate-y-0;
    }
    .btn-outline-default {
        @apply transition duration-100 rounded-md bg-transparent border border-gray-300 py-2 px-4 text-gray-400 hover:bg-gray-300 hover:text-white;
    }
    .input-box-vpo {
        @apply m-auto block w-full rounded-md border border-gray-300 bg-white py-2 mt-1 shadow-sm focus:ring-0 focus:border-primary sm:text-sm;
    }
    .no-scrollbar::-webkit-scrollbar {
        width: 0;
    }
    
    .table-striped:nth-child(even) {
        @apply bg-gray-200;
    }
    
    .no-spinner::-webkit-inner-spin-button,
    .no-spinner::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .no-spinner {
        -moz-appearance:textfield; /* For Firefox */
    }
    
}
.btn-primary:hover {
    background: var(--color-primaryHover);
}

.hover\:text-primary:hover .text-gray-900 {
    color: white !important;
}